import React, { useState } from "react";
import { IoLogoLinkedin, IoLogoFacebook, IoLogoTwitter, IoIosLink } from "react-icons/io";

import { CopyToClipboard } from 'react-copy-to-clipboard';

const SocialShare = ({ title, nodeLocale }) => {

  let titleTranslation = nodeLocale === 'en-US' ? 'Share:' : 'Jaa:';
  let linkCopyTranslation = nodeLocale === 'en-US' ? 'Copied' : 'Kopioitu';
  let linkCopyLabelTranslation = nodeLocale === 'en-US' ? 'Copy link' : 'Kopioi linkki';
  const url = typeof window !== 'undefined' ? window.location.href : 'https://www.editaprima.fi';

  // URL patterns for Social media sites share functionalities
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
  const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}`;

  const [isCopied, setIsCopied] = useState(false)
  const copyLink = () => {
    setIsCopied(true)
  }

  return (
    <div className="mb-6 py-6 flex items-center">
      <p className="pr-2 small-title text-black">{titleTranslation}</p>
      <ul className="flex items-center">
        <li className="px-2">
          <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
            <span className="sr-only">Facebook</span>
            <IoLogoFacebook size={36} />
          </a>
        </li>
        <li className="px-2">
          <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <span className="sr-only">Twitter</span>
            <IoLogoTwitter size={36} />
          </a>
        </li>
        <li className="px-2">
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <span className="sr-only">Linkedin</span>
            <IoLogoLinkedin size={36} />
          </a>
        </li>
        <li className="relative">
          {isCopied && <span className="absolute -top-8">{linkCopyTranslation}</span>}
          <CopyToClipboard
            text={url}
            onCopy={copyLink}
          >

            <div>
              <span className="sr-only">{linkCopyLabelTranslation}</span>
              <IoIosLink size={36} />
            </div>
          </CopyToClipboard>


        </li>
      </ul>
    </div>
  )


}

export default SocialShare;