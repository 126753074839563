import React, { useState, useEffect } from 'react';
import ServiceSection from './serviceSection';
import PeopleSection from './peopleSection';
import VideoImageSection from './videoImageSection';
import ValuesSection from './valuesSection';
import TimelineSection from './timelineSection';
import ListSection from './listSection';
import TextBanner from './textBanner';
import CaseSection from './caseSection';
import HighlightsSection from './highlightsSection';
import GroupStructure from './groupStructure';
import ReferenceSection from './referenceSection';
import ReferenceSectionMobile from './referenceSectionMobile';
import ContactSection from './contactSection';
import TextBoxSection from './textBoxSection';
import ProcessSection from './processSection';
import ContentBlockSection from './contentBlockSection';
import TextSectionQuote from './textSectionQuote';
import QuoteSection from './quoteSection';
import TextSectionWithColumns from './textSectionWithcolumns';
import InformationSection from './informationSection';
import GraphSection from './graphSection';
import Mapbox from './mapbox';

const ContentSectionsForArticle = ({ contentSections, nodeLocale }) => {

	const [width, setWidth] = useState(typeof window !== 'undefined' && window.innerWidth)

	useEffect(() => {
		const handleWindowResize = () => setWidth(typeof window !== 'undefined' && window.innerWidth);
		typeof window !== 'undefined' && window.addEventListener("resize", handleWindowResize);
		return () => typeof window !== 'undefined' && window.removeEventListener("resize", handleWindowResize);
	}, []);

	return (
		<div className="content-sections">
			{contentSections?.map((section, index) => {
				if (section.__typename === 'ContentfulTextSectionQuote') {
					return (
						<TextSectionQuote
							key={index}
							heading={section.heading}
							richText={section.richText}
							quote={section.quote}
						/>
					);
				} else if (section.__typename === 'ContentfulServicesSection') {
					return (
						<ServiceSection
							key={index}
							title={section.sectionTitle}
							serviceAreas={section.serviceArea}
							buttonLabel={section.buttonLabel}
							buttonLink={section.buttonLink.slug}
							nodeLocale={nodeLocale}
						/>
					)
				} else if (section.__typename === 'ContentfulPeopleSection') {
					return (
						<PeopleSection
							key={index}
							title={section.title}
							people={section.addPeople}
							columnCount={section.columnCount}
						/>
					);
				} else if (section.__typename === 'ContentfulVideoImageSection') {
					return (
						<VideoImageSection
							key={index}
							mediaType={section.videoOrImage.file.contentType}
							image={section.videoOrImage.gatsbyImageData}
							video={section.videoOrImage}
							fullWidth={section.fullWidth}
							linkUrl={section.linkUrl}
						/>
					);
				} else if (section.__typename === 'ContentfulValuesSection') {
					return (
						<ValuesSection
							key={index}
							sectionTitle={section.sectionTitle}
							values={section.values}
						/>
					);
				} else if (section.__typename === 'ContentfulTimelineSection') {
					return (
						<TimelineSection
							key={index}
							sectionTitle={section.sectionTitle}
							timelineItems={section.item}
							nodeLocale={nodeLocale}
						/>
					);
				} else if (section.__typename === 'ContentfulListSection') {
					return (
						<ListSection
							key={index}
							sectionTitle={section.sectionTitle}
							listItems={section.listItems}
						/>
					);
				} else if (section.__typename === 'ContentfulTextBanner') {
					return (
						<TextBanner
							key={index}
							sectionTitle={section.sectionTitle}
							richText={section.text}
						/>
					)
				} else if (section.__typename === 'ContentfulCaseSection') {
					return (
						<CaseSection
							key={index}
							sectionTitle={section.sectionTitle}
							title={section.title}
							client={section.client}
							serviceCategory={section.serviceCategory}
							richText={section.text}
							nodeLocale={nodeLocale}
							anchorLink={section.anchorLink}
						/>
					)
				} else if (section.__typename === 'ContentfulHighlightsSection') {
					return (
						<HighlightsSection
							key={index}
							highlights={section.items}
						/>
					)
				} else if (section.__typename === 'ContentfulGroupStructureSection') {
					return (
						<GroupStructure
							key={index}
							title={section.title}
							textField={section.textField}
							logo={section.logo.file.url}
							logoMobile={section.logoMobile.file.url}
							companies={section.companies}
						/>
					)
				} else if (section.__typename === 'ContentfulRefrenceSection') {
					return width < 640 ? (
						<ReferenceSectionMobile
							key={index}
							sectionTitle={section.sectionTitle}
							logos={section.logos}
						/>
					) :
						(
							<ReferenceSection
								key={index}
								sectionTitle={section.sectionTitle}
								logos={section.logos}
							/>
						)
				} else if (section.__typename === 'ContentfulFormSection') {
					return (
						<ContactSection
							key={index}
							sectionTitle={section.sectionTitle}
							firstName={section.firstName}
							lastName={section.lastName}
							email={section.email}
							phone={section.phone}
							message={section.message.message}
							texts={section.texts}
						/>
					)
				} else if (section.__typename === 'ContentfulTextBoxSection') {
					return (
						<TextBoxSection
							key={index}
							sectionTitle={section.sectionTitle}
							textBlocks={section.textBlocks}
							showContentInColumns={section.showContentInColumns}
						/>
					)
				} else if (section.__typename === 'ContentfulProcessSection') {
					return (
						<ProcessSection
							sectionTitle={section.sectionTitle}
							sectionDescription={section.sectionDescription}
							phases={section.phases}
						/>
					)
				} else if (section.__typename === 'ContentfulContentBlockSection') {
					return (
						<ContentBlockSection
							sectionTitle={section.sectionTitle}
							contentBlock={section.contentBlock}
						/>
					)
				} else if (section.__typename === 'ContentfulQuoteSection') {
					return (
						<QuoteSection
							richText={section.text}
						/>
					)
				} else if (section.__typename === 'ContentfulTextSectionWithColumns') {
					return (
						<TextSectionWithColumns
							sectionTitle={section.sectionTitle}
							sectionIngress={section.sectionIngress}
							textBlocks={section.textBlocks}
						/>
					)
				} else if (section.__typename === 'ContentfulMapbox') {
					return (
						<Mapbox
							token={section.token}
							styleUrl={section.styleUrl}
						/>
					)
				} else if (section.__typename === 'ContentfulInformationSection') {
					return (
						<InformationSection
							sectionTitle={section.sectionTitle}
							informationCards={section.informationCards}
						/>
					)
				} else if (section.__typename === 'ContentfulGraphSection') {

					return (
						<GraphSection
							sectionTitle={section.sectionTitle}
							sectionDescription={section.sectionDescription}
							graph={section.graph?.file.url}
						/>
					)
				} else {
					return null;
				}
			})}
		</div>
	)
};

export default ContentSectionsForArticle;