import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/layout';
import { BodyText } from '../components/richText/bodyText';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import Seo from '../components/seo/seo';
import Commento from '../components/commento/commento';
import SocialShare from '../components/socialShare/socialShare';
import ContentSectionsForArticle from "../components/contentSections/contentSectionsForArticle";

const Article = ({ data }) => {

  const { color, authorName, authorImage, newsType, authorTitle, title, date, bodyText, mainImage, node_locale, inpageBot, seoSettings, contentSections } = data.contentfulArticle;

  const seoDefaultImage = getSrc(mainImage.gatsbyImageData);
  const seoOverrideImage = getSrc(mainImage.gatsbyImageData);
  const colorTheme = new Array (color);  
  let authorTranslation = node_locale === 'en-US' ? 'Author' : 'Kirjoittaja';
  let dateTranslation = node_locale === 'en-US' ? 'Date' : 'Julkaisupäivä';

  let commentSectionTitle = node_locale === 'en-US' ? 'Comments are most welcome and appreciated' : 'Kommentit ovat erittäin tervetulleita ja arvostettuja';

  let category = '';

  if (newsType) {
    if (newsType.toString().toLowerCase() === 'all' || newsType.toString().toLowerCase() === 'kaikki') {
      category = node_locale === 'en-US' ? 'All' : 'Kaikki';
    } else if (newsType.toString().toLowerCase() === 'blog' || newsType.toString().toLowerCase() === 'blogi') {
      category = node_locale === 'en-US' ? 'Blog' : 'Blogi';
    } else if (newsType.toString().toLowerCase() === 'stories' || newsType.toString().toLowerCase() === 'tarinat') {
      category = node_locale === 'en-US' ? 'Stories' : 'Tarinat';
    } else if (newsType.toString().toLowerCase() === 'guides' || newsType.toString().toLowerCase() === 'oppaat') {
      category = node_locale === 'en-US' ? 'Guides' : 'Oppaat';
    } else if (newsType.toString().toLowerCase() === 'webinars' || newsType.toString().toLowerCase() === 'webinaarit') {
      category = node_locale === 'en-US' ? 'Webinars' : 'Webinaarit';
    } else {
      category = node_locale === 'en-US' ? 'News' : 'Uutiset';
    };
  }

  return (
    <Layout colorTheme={colorTheme} nodeLocale={node_locale}>
      <Seo
        seoTitle={seoSettings?.seoTitle ? seoSettings.seoTitle : title}
        seoDesc={seoSettings?.description ? seoSettings.description.description : null}
        keywords={seoSettings?.keywords ? seoSettings?.keywords : null}
        image={seoOverrideImage ? seoOverrideImage : seoDefaultImage}
        url={typeof window !== 'undefined' ? window.location.href : ''}
        lang={node_locale.substr(0, 2)}
        noIndex={seoSettings?.hidePageFromSearchEnginesNoindex ? 'nofollow' : 'follow'}
        noFollow={seoSettings?.excludeLinksFromSearchRankingsNofollow ? 'noindex' : 'index'}
      />
      <article className="px-4 sm:px-6 md:px-12 lg:px-20 pt-24 pb-2 xs:pt-32 lg:pt-40 lg:pb-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="md:-mx-6 flex flex-wrap">
            <div className="md:px-6 w-full md:w-3/12 lg:w-3/12">
              <h2 className="mb-6 heading-2-book">{category}</h2>
            </div>

            <div className="md:px-6 w-full md:w-9/12 lg:w-9/12">
              <header>
                <div className="mb-3 pb-3 border-b border-blackcurrant">
                  <div className="small-title flex flex-wrap items-center">
                    {authorImage &&
                      <figure className={`ar-box ar-box-1-1 relative z-10 rounded-full overflow-hidden bg-brightTurquoiseApprox h-24 w-24`}>
                        <GatsbyImage
                          image={authorImage.gatsbyImageData}
                          style={{ position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '2', height: '100%' }}
                          imgStyle={{ objectFit: 'cover', objectPosition: '0% 6px' }}
                        />
                      </figure>
                    }
                    <div className={`${authorImage && 'pl-4'} flex-1`}>
                      <div className="xl:pr-4">
                        <span className="small-title--semibold">{authorTranslation}: </span> {authorName} / {authorTitle}
                      </div>
                      <div className="pr-6">
                        <span className="small-title--semibold">{dateTranslation}: </span> {date}
                      </div>
                    </div>
                  </div>
                </div>

                <h1 className="mb-6 heading-3 text-blackcurrant">{title}</h1>
              </header>

              <SocialShare title={title} nodeLocale={node_locale} />

              {!!bodyText &&
                <div className="body-text body-text--article">
                  <BodyText
                    nodeLocale={node_locale}
                    raw={bodyText}
                    references={bodyText.references}
                  />
                </div>
              }

              <h5 className='mt-8 heading-6'>{commentSectionTitle}</h5>
              <Commento />

              {
                !!inpageBot &&
                <div dangerouslySetInnerHTML={{ __html: inpageBot.inpageBot }}></div>
              }

              <ContentSectionsForArticle nodeLocale={node_locale} contentSections={contentSections} />
            </div>
          </div >
        </div >
      </article >        
    </Layout >
  )
}

export const pageQuery = graphql`
  query newsPageQuery($slug: String, $locale: String) {
    contentfulArticle(slug: {eq: $slug }, node_locale: {eq: $locale }) {
      id
      node_locale
      color
      authorName
      authorTitle
      authorImage {
        gatsbyImageData
      }
      title
      newsType
      date
      bodyText{
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
          ... on ContentfulPage {
            contentful_id
            __typename
            slug
          }
        }
      }
      mainImage {
        gatsbyImageData
      }
      inpageBot {
        inpageBot
      }
      seoSettings {
        seoTitle
        description {
          description
        }
        keywords
        image {
          gatsbyImageData
        }
        excludeLinksFromSearchRankingsNofollow
        hidePageFromSearchEnginesNoindex
      }
      contentSections {
        ... on Node {
          ... on ContentfulPeopleSection {
            __typename
            id
            title
            addPeople {
              ... on Node {
                ... on ContentfulPerson {
                  name
                  position
                  email
                  telephoneNumber
                  imageBackgroundColor
                  image {
                    gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
            }
            columnCount
          }
          ... on ContentfulNewsSection {
            __typename
            id
            sectionTitle
            sectionName
            loadMoreButtonText
          }
          ... on ContentfulTextSection {
            __typename
            id
            title
            richText {
              raw
            }
            quote {
              raw
            }
            ctaButtonLinks {
              ... on Node { 
                ... on ContentfulExternalLink {
                  linkName
                  linkUrl
                }
                ... on ContentfulInternalLink {
                  id
                  linkName
                  linkToEntry {
                    ... on ContentfulPage {
                      id
                      slug
                    }
                  }
                }
                ... on ContentfulAssetLink {
                  collectUserInfo
                  linkName
                  addAsset {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulTextSectionQuote {
            __typename
            id
            sectionName
            heading {
              heading
            }
            richText {
              raw
            }
            quote {
              raw
            }
          }
          ... on ContentfulVideoImageSection {
            __typename
            id
            videoOrImage {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
              file {
                contentType
                url
              }
            }
            fullWidth
            linkUrl
          }
          ... on ContentfulMapbox {
            __typename
            token
            styleUrl
          }
          ... on ContentfulCtaSection {
            __typename
            id
            title
            titleColor
            textField {
              textField
            }
            ctaLinks {
              ... on Node { 
                ... on ContentfulExternalLink {
                  linkName
                  linkUrl
                }
                ... on ContentfulInternalLink {
                  id
                  linkName
                  linkToEntry {
                    ... on ContentfulPage {
                      id
                      slug
                    }
                  }
                }
              }
            }
            textAlignCenter
            backgroundVideo {
              file {
                url
              }
            }
            videoAlphaChannel {
              file {
                url
              }
            }
          }
          ... on ContentfulValuesSection {
            __typename
            id
            sectionTitle
            values {
              title
              image {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulTimelineSection {
            __typename
            id
            sectionTitle
            item {
              key
              value
            }
          }
          ... on ContentfulGroupStructureSection {
            __typename
            id
            title
            logo {
              file {
                url
              }
            }
            logoMobile {
              file {
                url
              }
            }
            companies {
              key
              value
            }
          }
          ... on ContentfulListSection {
            __typename
            id
            sectionTitle
            listItems {
              key
              value
            }
          }
          ... on ContentfulTextBanner {
            __typename
            id
            sectionTitle
            text {
              raw
            }
          }
          ... on ContentfulCaseSection {
            __typename
            id
            sectionTitle
            title
            client
            anchorLink
            serviceCategory
            text {
              raw
            }
          }
          ... on ContentfulRefrenceSection {
            __typename
            id
            sectionTitle
            logos {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          ... on ContentfulFormSection {
            __typename
            id
            sectionTitle
            firstName
            lastName
            email
            phone
            message {
              message
            }
            texts {
              send,
              requiredFirstName,
              requiredLastName,
              invalidEmail,
              excludedEmail,
              requiredPhone,
              requiredMessage,
              thankyouMessage,
              existingContact
            }
          }
          ... on ContentfulTextBoxSection {
            __typename
            id
            sectionTitle
            textBlocks {
              ... on Node {
                ... on ContentfulTextBlock {
                  id
                  blockName
                  blockContent {
                    raw
                  }
                }
              }
            }
            showContentInColumns
          }
          ... on ContentfulProcessSection {
            __typename
            id
            sectionTitle
            sectionDescription {
              sectionDescription
            }
            phases {
              text {
                raw
              }
              image {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulContentBlockSection {
            __typename
            id
            sectionTitle
            contentBlock {
              blockPretitle
              blockTitle
              content {
                raw
                references {
                  ... on ContentfulPage {
                    __typename
                    contentful_id
                    slug
                  }
                }
              }
            }
          }
          ... on ContentfulTextSectionWithColumns {
            __typename
            id
            sectionName
            sectionTitle
            sectionIngress {
              sectionIngress
            }
            textBlocks {
              ... on Node {
                ... on ContentfulTextBlock {
                  id
                  blockName
                  blockContent {
                    raw
                  }
                }
              }
            }
          }
          ... on ContentfulInformationSection {
            __typename
            id
            sectionTitle
            informationCards {
              id
              ... on Node {
                ... on ContentfulInformationCard {
                  content {
                    raw
                  }
                }
              }
            }
          }
          ... on ContentfulQuoteSection {
            __typename
            id
            text {
              raw
            }
          }
          ... on ContentfulGraphSection {
            __typename
            id
            sectionName
            sectionTitle
            sectionDescription {
              sectionDescription
            }
            graph {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Article;